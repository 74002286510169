.gradient-border {
	border-bottom: 8px solid;
	border-image-slice: 1;
	border-image-source: linear-gradient(to left,#6366F1, #7d7ff2);
  }

  .gradient-border {
	--angle: 0deg;
	border-image: linear-gradient(var(--angle), #6366F1, #7d7ff2) 1;
	animation: 5s rotate linear infinite;
  }
  
  @keyframes rotate {
	to {
	  --angle: 360deg;
	}
  }

  @property --angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
  }
