@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  font-family: "Inter var", "system-ui", 'Open Sans', sans-serif;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
}

h1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Clerk Authentication */

.mt-profile .cl-card > div:nth-child(3) {
  display: none;
}

.mt-signin .cl-card > div:nth-child(4) {
  display: none;
}

.mt-signin .cl-card > div:nth-child(5) {
  display: none;
}

.mt-signup .cl-card > div:nth-child(4) {
  display: none;
}

/* End of Clerk */

/* Toastify */
.Toastify__toast {
  border-radius: 11px !important;
  border: 1px solid #e5e7eb;
  font-size: 15px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
}

.Toastify__toast-body {
  color: black;
  font-family: system-ui;
  font-weight: 450;
  padding: 5px 15px !important;
}

.Toastify__toast-icon {
  height: 25px !important;
  width: 25px !important;
}

.Toastify__close-button {
  opacity: 0.4;
  padding: 6px !important;
}

/* End Toastify */